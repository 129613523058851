<template>
    <div class="lct-detail-content">
        <div class="lct-detail-head">
            <div class="lct-detail-head-main">
                <div class="lct-detail-head-crumbs" v-if="info.title">
                    <span class="on">课程</span><i class="iconfont icon-jiantou"></i><router-link to="/course">付费课程</router-link><i class="iconfont icon-jiantou"></i><span>{{ info.title }}</span>
                </div>

                <div class="new-detail-head">
                    <div class="lct-detail-head-banner" v-if="info.banner">
                        <!--<img :src="info.banner"/>-->
                        <el-image style="width: 100%;" :src="info.banner" fit="cover"></el-image>
                    </div>


                    <div class="new-detail-info">

                        <div class="lct-detail-head-title">
                            <h4>{{ info.title }}</h4>
                            <div class="share-show">
                                <img class="wx" src="https://image.ysxxlm.cn/ysxxlm/pc/static/img/joinUs/weixin@2x.png"/>
                                <div class="vue-qr-box">
                                    <vue-qr :text="downloadData.url" colorLight="#fff" :logoSrc="downloadData.icon + '?cache'" :logoScale="0.2" :size="200" :margin="20"></vue-qr>
                                </div>
                            </div>
                        </div>
                        <div class="lct-detail-head-author" v-if="info.author">
                            <div class="lct-detail-head-author-flex"><strong>主讲嘉宾</strong><span>{{ info.author }}</span></div>
                            <div class="lct-detail-head-author-price">
								<!-- v-if="parseFloat(info.price)<parseFloat(info.old_price)" -->
								<span v-if="info.price=='0.00'">免费</span>
								<div v-else>
									<template v-if="info.event_price">
										<span v-if="parseFloat(info.price)<parseFloat(info.old_price)" style="text-decoration: line-through;color: #999;font-weight: normal">{{info.old_price}}</span>
										<span style="font-size: 20px">{{info.event_price}}</span>
									</template>
									<template v-else>
										<text>{{ info.price }}</text>
									</template>
								</div>
                                <!-- <span :style="info.event_price?'text-decoration: line-through;color: #999;font-weight: normal':''">
									{{ info.price == '0.00'?'免费':info.price }}
								</span>
                                <span style="font-size: 20px" v-if="info.event_price">{{ info.event_price }}</span> -->
                            </div>
                        </div>
                        <!--<div><strong>单位</strong><span>{{ info.author }}</span></div>-->
                        <div class="lct-detail-head-rank_sf">
                            <div class="lct-detail-head-rank_sf-l" v-if="info.rank_sf">
                                <strong>观看权限</strong>
                                <span v-for="(item,index) in info.rank_sf.split(',')" :key="index">{{ item }}</span>
                            </div>
                            <div class="lct-detail-head-rank_sf-c" v-if="info.study_count">
                                已有{{ info.study_count }}人报名
                            </div>

                        </div>
						<div class="lct-detail-head-rank_sf-btn" v-if="info.is_try==1">
							<button @click="toTry()">试看课程</button>
						</div>
                        <div class="lct-detail-head-rank_sf-btn" v-else>
                            <button @click="toPath()">{{ info.is_buy == 0?'立即购买':'立即观看' }}</button>
                        </div>

                    </div>

                </div>




            </div>
        </div>

        <div class="lct-detail-intro" v-if="info.details">
            <div class="lct-detail-intro-title">
                <strong>课程介绍</strong>
            </div>
            <div v-html="info.details"></div>
        </div>

        <el-dialog :visible.sync="payVisible" title="学习联盟" width="400px">
            <div class="modal-txt">
                <vue-qr class="vue-qr" :margin="0" :text="`${baseUrl}/ysxxlm/index.html#/pages/live/f_details?id=${$route.params.id}&share=1`" colorDark="#000" colorLight="#fff" :size="200"/>
                <p style="margin-top: 20px;">此课程您还未付费，请微信扫码购买此课程</p>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import config from '@/api/url'
    import vueQr from 'vue-qr'
    export default {
        data() {
            return {
                info: {},
                payVisible: false,
                isdjbm: false,
                baseUrl: config.baseUrl,
                downloadData: {
                    url: `https://miniapp.ysxxlm.com/ysxxlm/index.html#/pages/live/f_details?id=${this.$route.params.id}&share=1`,
                    icon: require('../../assets/img/logo.jpg')
                }
            }
        },
        components: {
            'vue-qr': vueQr,
        },
        watch:{
            // $route(to,from){
            //     if(to.query.code) {
            //         setTimeout( ()=> {
            //             if(this.isdjbm) {
            //                 setTimeout(()=> {
            //                     this.pay()
            //                 },500)
            //             }
            //             this.getDetail();
            //         },1000)
            //     }
            // },
			'$store.state.uid'(value){
				if(this.isdjbm) {
					setTimeout(()=> {
						this.pay()
					},500)
				}
				this.getDetail();
			}
        },
        computed: {
            userInfo() {
                let userInfo = '';
                if(this.$store.state.userInfo && this.$store.state.userInfo != undefined){
                    userInfo = this.$store.state.userInfo
                }
                return userInfo
            },
        },
        mounted() {
            this.getDetail();
        },
        methods: {
            getDetail() {
                this.$axios.post(this.apiUrl.courseDetails, {
                    id: this.$route.params.id
                }).then(res => {
					this.info = res.data;
					let metaInfo = {
						title: this.info.title,
						keywords: this.info.tags_keyword,
						description: this.info.title
					}
					this.$store.commit("CAHNGE_META_INFO", metaInfo)
                })
            },
            toPath() {
                if(!this.userInfo || this.userInfo == undefined) {
                    this.isdjbm = true;
                    this.$store.commit('wxLogin',true);
                    return false
                }
                this.pay()
            },
			toTry(){
				this.$router.push({path: `/course/${this.$route.params.id}/play`})
			},
            pay() {
                this.$axios.post(this.apiUrl.course, {
                    id: this.$route.params.id
                }).then(res => {
                    if(res.code == 1){
                        this.$router.push({
                            path: `/course/${this.$route.params.id}/play`
                        })
                    }else {
                        this.payVisible = true;
                    }
                })
            },
        }
    }
</script>

<style scoped>

</style>
